import { ChangeEventHandler, useEffect, useRef } from 'react'
import { AxiosResponse } from 'axios'
import _ from 'lodash'

import { ShopTabColor } from './ColorInput/ShopTabColor'
import { ShopTabMedia } from './Media/ShopTabMedia'

import { ImageFileUploading } from '../../../utils/upload'

import { tabBackgroundDimensions, tabIconDimensions } from '../../../settings'

import { MediaType } from '../../../types/enums/mediatype.enum'
import { TabType } from '../../../types/enums/tabtype.enum'

import { MediaOrImageFileUploading, Tab } from '../../../types/tabTypes'

type ShopTabAssetsWrapperProps = {
    tab: Tab
    onMediaChanged: (items: MediaOrImageFileUploading[]) => void
    onColorInputChange: ChangeEventHandler
}

export const ShopTabAssetsWrapper = (props: ShopTabAssetsWrapperProps) => {
    const { tab, onMediaChanged, onColorInputChange } = props
    const mediasRef = useRef(tab.medias)

    useEffect(() => {
        mediasRef.current = tab.medias
    }, [tab])

    const getMediasByType = (type: MediaType): MediaOrImageFileUploading[] => {
        return mediasRef.current.filter((item) => item.type === type)
    }

    const onMediaDragEnd = (result: {
        source: { index: number; droppableId: MediaType }
        destination: { index: number }
    }) => {
        if (!result.destination) return

        // Take only specific type of medias and rearrange them
        const typedMedia = Array.from(
            getMediasByType(result.source.droppableId)
        )

        const [removed] = typedMedia.splice(result.source.index, 1)
        typedMedia.splice(result.destination.index, 0, removed)

        // Take all other medias and concat them with rearranged ones
        const allUpdatedMedias = _.concat(
            mediasRef.current.filter(
                (item) => item.type !== result.source.droppableId
            ),
            typedMedia
        )

        onMediaChanged(allUpdatedMedias)
    }

    const onMediaSelect = (type: MediaType, files: ImageFileUploading[]) => {
        const updatedMedias = mediasRef.current.concat(
            files.map((file) => ({
                ...file,
                type,
            }))
        )

        onMediaChanged(updatedMedias)
    }

    const onMediaWasUploaded = (
        _type: MediaType,
        file: File,
        res: AxiosResponse<{ uploaded: { id: number } }>
    ) => {
        const updatedMedias = mediasRef.current.map((m) => {
            if ('file' in m && m.file.name === file.name) {
                return _.assign(m, {
                    uploading: false,
                    id: res.data.uploaded.id,
                })
            }
            return m
        })

        onMediaChanged(updatedMedias)
    }

    const onMediaDelete = (index: number, type: MediaType) => {
        // Get only specific type of medias and remove one of them by index
        const [mediaToDelete] = getMediasByType(type).splice(index, 1)

        onMediaChanged(
            mediasRef.current.filter((item) => item.id !== mediaToDelete.id)
        )
    }

    return (
        <>
            {tab.type === TabType.PACKS_LIST && (
                <ShopTabMedia
                    tab={tab}
                    header={`Background image (${tabBackgroundDimensions.w} x ${tabBackgroundDimensions.h})`}
                    type={MediaType.TAB_BACKGROUND}
                    onMediaDragEnd={onMediaDragEnd}
                    onMediaSelect={onMediaSelect}
                    onMediaWasUploaded={onMediaWasUploaded}
                    onMediaDelete={onMediaDelete}
                />
            )}
            <ShopTabColor tab={tab} onColorInputChange={onColorInputChange} />
            <ShopTabMedia
                tab={tab}
                header={`Tab icon (${tabIconDimensions.w} x ${tabIconDimensions.h})`}
                type={MediaType.TAB_ICON}
                onMediaDragEnd={onMediaDragEnd}
                onMediaSelect={onMediaSelect}
                onMediaWasUploaded={onMediaWasUploaded}
                onMediaDelete={onMediaDelete}
                imageSize="@3x"
            />
        </>
    )
}
