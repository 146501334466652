import {
    AppEvent,
    AppEventAssetType,
    AppEventAttributesPriority,
    AppEventAttributesPurchaseRequirement,
    AppEventLocalization,
    AppEventLocalizationCreateRequestData,
    AppEventLocalizationCreateRequestDataType,
    AppEventScreenshot,
} from '@tocaboca/app-store-connect'
import _ from 'lodash'
import moment from 'moment-timezone'

import { appleLanguageMapping } from '../utils/utils'

import { LanguageType } from '../types/enums/languagetype.enum.ts'
import { MediaType } from '../types/enums/mediatype.enum'

import { InAppEvent, InAppEventTranslations } from '../types/inappeventTypes'
import { AppleMedia } from '../types/mediaTypes'

export enum AppEventDateEnum {
    PUBLISH_START = 'publishStart',
    EVENT_START = 'eventStart',
    EVENT_END = 'eventEnd',
}

export const assetTypeToMediaType = {
    [AppEventAssetType.EVENT_CARD]: MediaType.IAE_CARD,
    [AppEventAssetType.EVENT_DETAILS_PAGE]: MediaType.IAE_DETAILS_PAGE,
}

export function getAppEventDate(
    appEvent: AppEvent,
    dateType: AppEventDateEnum
): string | undefined {
    const tz = 'Europe/Stockholm'
    const format = 'YYYY-MM-DDTHH:mm'
    let resultDate

    if (appEvent.attributes?.territorySchedules?.length) {
        resultDate = appEvent.attributes?.territorySchedules[0][dateType]
    }
    if (appEvent.attributes?.archivedTerritorySchedules?.length) {
        resultDate =
            appEvent.attributes?.archivedTerritorySchedules[0][dateType]
    }

    return resultDate ? moment(resultDate).tz(tz).format(format) : undefined
}

export function setAppEventDate(
    appEvent: AppEvent,
    dateType: AppEventDateEnum,
    value: string
) {
    if (!appEvent.attributes) {
        appEvent.attributes = {}
    }
    if (!appEvent.attributes.territorySchedules) {
        appEvent.attributes.territorySchedules = []
    }

    appEvent.attributes.territorySchedules[0] = {
        ...appEvent.attributes.territorySchedules[0],
        [dateType]: value,
    }
}

export enum AppEventLocalisationEnum {
    NAME = 'name',
    SHORT_DESCRIPTION = 'shortDescription',
    LONG_DESCRIPTION = 'longDescription',
}

export function getAppEventLocalisation(
    localisations: AppEventLocalization[] | undefined,
    locale: LanguageType,
    localeKey: AppEventLocalisationEnum
): string | undefined {
    return localisations?.find(
        (l) => l.attributes?.locale === appleLanguageMapping[locale]
    )?.attributes?.[localeKey]
}

export function setAppEventLocalisation(
    localisations: (
        | AppEventLocalization
        | Pick<AppEventLocalizationCreateRequestData, 'attributes' | 'type'>
    )[],
    locale: LanguageType,
    localeKey: AppEventLocalisationEnum,
    value: string
) {
    const localisation = localisations.find(
        (l) => l.attributes?.locale === appleLanguageMapping[locale]
    )

    if (localisation) {
        localisation.attributes = {
            ...localisation.attributes,
            [localeKey]: value,
        }
        return
    }
    const newLocalisation = {
        type: AppEventLocalizationCreateRequestDataType.appEventLocalizations,
        attributes: {
            locale: appleLanguageMapping[locale],
            [localeKey]: value,
        },
    }
    localisations.push(newLocalisation)
}

export function setAppEventLocalisations(
    localisations: AppEventLocalization[],
    translations: InAppEventTranslations
) {
    for (const [fieldName, translation] of Object.entries(translations)) {
        for (const [locale, value] of Object.entries(translation)) {
            if (value) {
                setAppEventLocalisation(
                    localisations,
                    locale as LanguageType,
                    fieldName as AppEventLocalisationEnum,
                    value
                )
            }
        }
    }
}

export function isInAppPurchaseRequired(appEvent: AppEvent) {
    return (
        appEvent.attributes?.purchaseRequirement ===
        AppEventAttributesPurchaseRequirement.IN_APP_PURCHASE
    )
}

export function isHighPriority(appEvent: AppEvent) {
    return appEvent.attributes?.priority === AppEventAttributesPriority.HIGH
}

export function getAppEventStoreUrl(iae: InAppEvent) {
    return `https://appstoreconnect.apple.com/apps/${iae.appId}/distribution/events/${iae.appEvent.id}`
}

export function getAppEventUrl(iae: InAppEvent) {
    return iae.appEvent.id
        ? `https://apps.apple.com/app/id${iae.appId}?eventid=${iae.appEvent.id}`
        : ''
}

export function getAppEventScreenshotImageUrl(
    screenshot: AppEventScreenshot | null,
    width: number,
    height: number,
    type: 'jpeg' | 'png'
): string | undefined {
    return screenshot?.attributes?.imageAsset?.templateUrl
        ?.replace('{w}', width.toString())
        .replace('{h}', height.toString())
        .replace('{f}', type)
}

export function mapAppEventScreenshotToMedia(
    screenshot: AppEventScreenshot,
    width: number,
    height: number
): AppleMedia {
    const assetType = screenshot.attributes?.appEventAssetType
    if (!assetType) {
        throw new Error('Failed to map asset type for the app event')
    }
    return {
        id: screenshot.id,
        type: assetTypeToMediaType[assetType],
        // e.g. https://is1-ssl.mzstatic.com/image/thumb/FF_K239lK-yLoLIu8awsNQ/{w}x{h}bb.{f}
        url:
            getAppEventScreenshotImageUrl(screenshot, width, height, 'jpeg') ||
            '',
        backgroundColor: '',
        heightRatio: 1,
        md5: '',
    }
}

export function getTranslationLoadingText(
    referenceName: string | undefined,
    translations:
        | Record<string, Partial<Record<LanguageType, string>>>
        | undefined,
    isPending: boolean,
    error: Error | null
) {
    if (referenceName === undefined) {
        return ''
    }
    if (error) {
        return `That didn't work. Sorry! Server says: ${error.toString()}`
    }
    if (isPending) {
        return 'Loading from google...'
    }
    if (typeof translations === 'object' && _.isEmpty(translations)) {
        return 'There are no translations for this event!'
    }
    if (translations) {
        return "I've updated the text for you!"
    }
    return ''
}
