import { FormEvent } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { AxiosResponse } from 'axios'
import _ from 'lodash'

import { DraggablePhotos } from '../../../../shared'

import {
    ImageFileUploading,
    onSelectPhoto,
    OptionalData,
} from '../../../../utils/upload'

import {
    MediaType,
    ShopTabMediaTypes,
} from '../../../../types/enums/mediatype.enum'

import { Tab } from '../../../../types/tabTypes'

const NoMediaPlaceholder = () => (
    <div className="noMediaWrapper">
        <label className="placeholder">
            No files, just yet. Click 'Add new' to upload
        </label>
    </div>
)

const fileNameSuffix = {
    [MediaType.TAB_ICON]: '_icon',
    [MediaType.TAB_BACKGROUND]: '_bg',
}

type ShopTabMediaProps = {
    tab: Tab
    header: string
    type: ShopTabMediaTypes
    onMediaDragEnd: (result: {
        source: { index: number; droppableId: MediaType }
        destination: { index: number }
    }) => void
    onMediaSelect: (
        type: MediaType,
        items: ImageFileUploading[],
        optionalData: OptionalData
    ) => void
    onMediaWasUploaded: (
        type: MediaType,
        file: File,
        res: AxiosResponse<{ uploaded: { id: number } }>
    ) => void
    onMediaDelete: (index: number, type: MediaType) => void
    imageSize?: string
}

export const ShopTabMedia = (props: ShopTabMediaProps) => {
    const {
        tab,
        header,
        type,
        onMediaDragEnd,
        onMediaSelect,
        onMediaWasUploaded,
        onMediaDelete,
    } = props
    const imageSize = props.imageSize || '@1x'
    const mediaItems = tab.medias.filter((item) => item.type === type)

    const onAddNewClicked = (e: FormEvent<HTMLInputElement>) =>
        onSelectPhoto(e, type, onMediaSelect, onMediaWasUploaded, {
            fileName: tab.name?.toLowerCase() + fileNameSuffix[type],
        })

    return (
        <div className="part">
            <h2>{header}</h2>
            <DndProvider backend={HTML5Backend}>
                {_.isEmpty(mediaItems) && <NoMediaPlaceholder />}
                <DraggablePhotos
                    onDelete={onMediaDelete}
                    type={type}
                    items={mediaItems}
                    onDragEnd={onMediaDragEnd}
                    imageSize={imageSize}
                />
                <div style={{ marginTop: 20 }}>
                    <input
                        type="file"
                        multiple={true}
                        name={type}
                        id={type}
                        accept="Image/png"
                        onChange={onAddNewClicked}
                    />
                    <label className="upload" htmlFor={type}>
                        Add new
                    </label>
                </div>
            </DndProvider>
        </div>
    )
}
