import {
    Dimensions as ExpectedDimensions,
    iaeCardDimensions,
    iaeDetailsPageDimensions,
    iapImageDimensions,
    mapDimensions,
    packScreenshotDimensions,
    packScrollingScreenshotDimensions,
    packTabThumbnailDimensions,
    packThumbnailDimensions,
    packWideThumbnailDimensions,
    tabBackgroundDimensions,
    tabIconDimensions,
} from '../settings'

import { MediaType } from '../types/enums/mediatype.enum'

type InputDimensions = { w: number; h: number }

export const mediaTypeToeExpectedDimensions: Partial<
    Record<MediaType, ExpectedDimensions | ExpectedDimensions[]>
> = {
    [MediaType.SCREENSHOT]: packScreenshotDimensions,
    [MediaType.THUMBNAIL]: packThumbnailDimensions,
    [MediaType.WIDE_THUMBNAIL]: packWideThumbnailDimensions,
    [MediaType.TAB_THUMBNAIL]: packTabThumbnailDimensions,
    [MediaType.SCROLLING_SCREENSHOT]: packScrollingScreenshotDimensions,
    [MediaType.TAB_BACKGROUND]: tabBackgroundDimensions,
    [MediaType.TAB_ICON]: tabIconDimensions,
    [MediaType.IAP_IMAGE]: iapImageDimensions,
    [MediaType.MAP_BACKGROUND]: mapDimensions,
    [MediaType.IAE_CARD]: iaeCardDimensions,
    [MediaType.IAE_DETAILS_PAGE]: iaeDetailsPageDimensions,
}

export function validateDimensions(
    dimension: InputDimensions,
    expectedDimensions: ExpectedDimensions | ExpectedDimensions[]
) {
    if (Array.isArray(expectedDimensions)) {
        for (const expectedDimension of expectedDimensions) {
            try {
                return validateDimensions(dimension, expectedDimension)
            } catch (err) {
                continue
            }
        }
        const expectedDimensionsDisplayText = expectedDimensions
            .map((dimension) => `${dimension.h}x${dimension.w}`)
            .join(', ')
        throw new Error(
            `The image must be in one of the following dimensions: ${expectedDimensionsDisplayText}`
        )
    }
    if (expectedDimensions.w && expectedDimensions.w !== dimension.w) {
        throw new Error(`The width must be ${expectedDimensions.w}px`)
    }
    if (expectedDimensions.h && expectedDimensions.h !== dimension.h) {
        throw new Error(`The height must be ${expectedDimensions.h}px`)
    }
    if (expectedDimensions.minW && dimension.w < expectedDimensions.minW) {
        throw new Error(
            `The width must be at least ${expectedDimensions.minW}px`
        )
    }
    if (expectedDimensions.maxW && dimension.w > expectedDimensions.maxW) {
        throw new Error(
            `The width must be less than or equal to ${expectedDimensions.maxW}px`
        )
    }
    if (
        'ratio' in expectedDimensions &&
        expectedDimensions.ratio !== dimension.h / dimension.w
    ) {
        throw new Error(
            `The aspect ratio must be ${expectedDimensions.ratio?.toFixed(2)}`
        )
    }
}

export async function validateImageDimensions(
    url: string,
    mediaType: MediaType
) {
    const expectedDimension = mediaTypeToeExpectedDimensions[mediaType]
    if (!expectedDimension) {
        return
    }
    const image = new Image()
    return new Promise<void>((res, rej) => {
        image.onload = () => {
            const imageSize = { w: image.width, h: image.height }
            try {
                validateDimensions(imageSize, expectedDimension)
            } catch (err) {
                rej(err)
            }
            res()
        }
        image.onerror = rej
        image.src = url
    })
}
