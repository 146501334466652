export enum MediaType {
    VIDEO = 'video',
    SCREENSHOT = 'screenshot',
    THUMBNAIL = 'thumbnail',
    WIDE_THUMBNAIL = 'widethumbnail',
    MAP_BACKGROUND = 'mapbackground',
    MAP_LOCATION = 'maplocation',
    UNKNOWN = 'unknown',
    SCROLLING_SCREENSHOT = 'scrollingscreenshot',
    TAB_THUMBNAIL = 'tabthumbnail',
    TAB_BACKGROUND = 'tabbackground',
    TAB_ICON = 'tabicon',
    IAP_IMAGE = 'iapimage',
    IAE_CARD = 'iaecard',
    IAE_DETAILS_PAGE = 'iaedetailspage',
}

export type MediaTypeUnion = `${MediaType}`

export type ShopTabMediaTypes = MediaType.TAB_BACKGROUND | MediaType.TAB_ICON
