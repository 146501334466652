import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

import { InAppEventTranslations } from '../types/inappeventTypes'

export function useIaeTranslations(referenceName?: string) {
    async function getIaeTranslations() {
        const { data } = await axios.get<InAppEventTranslations>(
            `/api/v2/iae/${referenceName}/translations/`
        )
        return data
    }
    const { data, isPending, error } = useQuery({
        queryKey: ['iaeTranslations', referenceName],
        queryFn: getIaeTranslations,
        enabled: referenceName !== undefined,
    })

    return {
        translations: data,
        isPending,
        error,
    }
}
