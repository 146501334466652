import { useQuery } from '@tanstack/react-query'

import { getEnabledFeatures } from '../utils/features'

export function useEnabledFeatures() {
    const { isPending, data } = useQuery({
        queryKey: ['enabledFeatures'],
        queryFn: () => getEnabledFeatures(),
    })

    return {
        enabledFeatures: data,
        isPending,
    }
}
